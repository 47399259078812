<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <h4>Edit Reward Category</h4>      
          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>

            <CForm>
              <CInput
                label="Name"
                v-model="name"
                placeholder ="Enter Reward Name"
                horizontal
              /> 
              <CInput
                label="Description" 
                v-model="description"             
                placeholder="Enter Reward Description"
                horizontal                
              />             

              <b-row>
                  <b-col sm="3"></b-col>   
                  <b-col sm="9"> 
                       <b-button-group>
                         <router-link to="/reward/category" tag="b-button">Cancel</router-link>
                         <b-button  v-on:click="actionRewardCategory()" variant="primary">Update</b-button>
                       </b-button-group>    
                  </b-col>                  
              </b-row><br/>
            </CForm>   

           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>

<script>
 import RewardService from '@/api/RewardService.js'; 

  export default {    
    data() {
      return {  
          name:'',
          description:'',  
          viewOrder: 0,         
          seen:false,
          msg :'',
          color:''          
        };  
    },
    created () {  
      
      this.getRewardCategoryById(this.$route.params.id);
      
    },
    methods: {

      async actionRewardCategory(){ 
         
        let params = {}; var validate = [];
        var idRecd = this.$route.params.id;
        params['name'] = this.name;
        params['description'] = this.description;   
        params['viewOrder'] = this.viewOrder;        

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false)){  
            this.updateRewardCategory(idRecd,params); 
        } else {
           this.msg   = 'Data Not Complete !'; 
           this.color = 'warning'; this.seen  = true;          
        } 

        setTimeout( () =>  this.seen=false , 5000);
      },

      getRewardCategoryById: function(id) {
        RewardService.getRewardCategoryById(id).then(resp => {  //console.log(resp);
           this.name = resp.name;
           this.description = resp.description;
           this.viewOrder = resp.viewOrder;
        }, error => {
          this.spinner = false;
        });
      },

      getRewardCategoryList: function() {
        RewardService.getRewardCategoryList().then(resp => {  //console.log(resp.length);
           this.order = resp.length;
        }, error => {
          this.spinner = false;
        });
      },
     
      updateRewardCategory: function(id, params) { 
          RewardService.updateRewardCategory(id, params).then(resp => {  //console.log(resp);

            if(resp.message=='Success'){
                this.msg   = 'Update Category Successfully'; 
                this.color = 'success';  this.seen  = true;               
              } else {
                this.msg   = !resp.data.message ? "Update Reward Category Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;                
              }
              setTimeout( () =>  this.seen=false , 5000);  
            
           }, error => {
            console.log('error');    
          });
      },        
      
    }
  }
</script>